import domReady from '@wordpress/dom-ready';

const closeAlert = () => {
  const alertBand = document.querySelector('.alert-band');
  const alertCloseButton = document.querySelector('.alert__close');
  const alertSessionStorage = sessionStorage.getItem('AlertBand');

  if (alertBand && alertCloseButton) {
    if (alertSessionStorage && 'alert-hidden' === alertSessionStorage) {
      alertBand.remove();
    } else {
      alertCloseButton.addEventListener('click', function () {
        alertBand.classList.toggle('closeAlert');
        sessionStorage.setItem('AlertBand', 'alert-hidden');
        alertBand.remove();
      });
    }
  }
};

domReady(() => {
  closeAlert();
});
